<template>
  <div class="text-center">
    <!-- <div class="spinner-grow text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-secondary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div> -->
    <div class="spinner-grow text-danger mx-md-2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-danger mx-md-2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-danger mx-md-2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-danger mx-md-2" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <!-- <div class="spinner-grow text-warning" role="status">
      <span class="sr-only">Loading...</span>
    </div> -->
    <!-- <div class="spinner-grow text-info" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-light" role="status">
      <span class="sr-only">Loading...</span>
    </div>
    <div class="spinner-grow text-dark" role="status">
      <span class="sr-only">Loading...</span>
    </div> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
