<template>
  <div>
    <div class="row justify-content-between align-items-center mb-3">
      <div class="col-md-8">
        <p class="h3">All Notifications</p>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          class="btnd btn-success text-white mx-0"
          data-toggle="modal"
          data-target="#createCategoriesModal"
        >
          ADD NEW
        </button>
      </div>
    </div>
    <div v-if="!pageloading" class="row">
      <div class="col-md-4 mb-3" v-for="(item, index) of category" :key="index">
        <div class="card shadow border-0" style="height: 100%">
          <div class="card-body px-2 border-0 d-flex flex-column">
            <div>
              <TextTitleDisplay title="Category" :content="item.category" />
              <TextTitleDisplay title="Subject" :content="item.subject" />
              <TextTitleDisplay
                title="Body"
                :content="`${item.body.substring(0, 20)} . . . `"
              />
            </div>
            <div class="mt-auto">
              <div class="d-flex justify-content-end">
                <router-link
                  :to="'/notifications/' + item.id"
                  class="btnd btn-success text-white py-1"
                >
                  <span class="small">view</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="category.length == 0">
        <p class="h3 font-weight-normal text-danger text-center">
          There's No Notification
        </p>
      </div>
    </div>
    <Spinner v-if="pageloading" />
    <AddModal />
  </div>
</template>

<script>
import NotificationService from "../../services/notifications.service";
import Spinner from "@/components/Spinner";
import AddModal from "../../components/notification/AddModal.vue";
import TextTitleDisplay from "../../components/texts/TextTitleDisplay.vue";

export default {
  name: "AllSupport",
  components: { Spinner, AddModal, TextTitleDisplay },
  data() {
    return {
      category: [],
      pageloading: true,
      currentEdit: {},
      loading: false,
    };
  },
  methods: {
    async loadData() {
      try {
        let response = await NotificationService.fetchAll();
        this.category = response.data.rows.reverse();
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message: error.msg,
          type: "error",
          position: "top-right",
          duration: 2000,
          pauseOnHover: true,
        });
      }
    },
  },
  async created() {
    await this.loadData();
    this.pageloading = false;
  },
};
</script>

<style scoped></style>
