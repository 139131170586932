<template>
  <div class="my-0 py-0">
    <span class="my-0 h6"> {{ title }}: </span>
    <span class="h6 font-weight-normal" v-html="content"></span>
  </div>
</template>

<script>
export default {
  name: "TextTitleDisplay",
  props: ["title", "content"],
};
</script>
