/* eslint-disable no-async-promise-executor */
import axios from "axios";

export default {
    fetchAll() {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.get(`/notification`);
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
    createNotification(body) {
        return new Promise(async(resolve, reject) => {
            try {
                const response = await axios.post(`/notification`, body);
                resolve(response.data);
            } catch (error) {
                reject(error.response.data);
            }
        });
    },
};