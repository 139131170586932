<template>
  <div>
    <div
      class="modal fade"
      id="createCategoriesModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">
              Send Notification
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="card">
              <div class="card-body">
                <div>
                  <form @submit.prevent="CreateCategory">
                    <div class="form-group">
                      <label>Subject</label>
                      <input
                        type="text"
                        required
                        class="form-control"
                        v-model="subject"
                        placeholder="Enter Notification Subject"
                      />
                    </div>
                    <div class="form-group">
                      <label>Category</label>
                      <input
                        type="text"
                        class="form-control"
                        v-model="category"
                        required
                        placeholder="Notification Category"
                      />
                    </div>
                    <div class="form-group">
                      <label>Body</label>
                      <textarea
                        class="form-control"
                        cols="20"
                        v-model="body"
                        rows="8"
                        placeholder="Type Subject here..."
                      ></textarea>
                    </div>

                    <button
                      v-if="!loadingUpdate"
                      type="submit"
                      class="btnd btn-success text-light"
                    >
                      Send
                    </button>
                    <button
                      v-else
                      class="btnd btn-success text-light"
                      type="button"
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationService from "../../services/notifications.service";
export default {
  name: "AddCategoryModal",
  data() {
    return {
      loadingUpdate: false,
      subject: "",
      category: "",
      body: "",
    };
  },

  methods: {
    async CreateCategory() {
      this.loadingUpdate = true;
      var message, type;
      try {
        const response = await NotificationService.createNotification({
          subject: this.subject,
          category: this.category,
          body: this.body,
        });
        type = "success";
        message = response.msg;
      } catch (error) {
        type = "error";
        message = error.msg;
      }

      this.$toast.open({
        message,
        type,
        position: "top-right",
        duration: 2000,
        pauseOnHover: true,
      });

      this.loadingUpdate = false;
      if (type == "success") {
        setTimeout(() => {
          location.reload();
        }, 2300);
      }
    },
  },
};
</script>
